import { defineStore } from 'pinia';

export const createCompanyLocationParamState = (id) => {
    return defineStore({
        id: `commonSearchParamState-${id}`,
        state: () => ({
            isCompanyLoading: false,
            isLocationLoading: false,
            companySelected: '',
            locationSelected: '',
            companies: [],
            locations: [],
        }),
        getters: {
            isLoading() {
                return this.isCompanyLoading || this.isLocationLoading;
            },
        },
        actions: {
            setCompanySelected(company) {
                this.companySelected = company;
            },
            setLocationSelected(location) {
                this.locationSelected = location;
            },
            // 他のアクションを追加可能
        },
    })();
};
