import { defineStore } from 'pinia';

export const useCommonSearchParamState = defineStore({
    id: 'commonSearchParamState',
    state: () => ({
    }),
    getters: {
    },
    actions: {
    },
});
