<template />
<script>
 import { DateTime } from "luxon";
 import { makeStartOfToday, makeEndOfToday, makeStartOfYesterday, makeEndOfYesterday,
          minEdgeOfDate, maxEdgeOfDate, minEdgeOfDateString, maxEdgeOfDateString,
          makeMinEdgeOfSelectedDate, makeMaxEdgeOfSelectedDate,
 } from '@/composables/datetime.js';
 import { isStringEmpty } from '@/composables/common.js';

 export default {
     props: {
         realtimeValidation: {type: Boolean, default: false},
     },
     data: function() {
         return {
             dateFrom: '',
             dateTo: '',
             minEdgeOfDateString: null,
             maxEdgeOfDateString: null,
             lastValidDateFrom: null,
             lastValidDateTo: null,
             isFocusedDateFrom: false,
             isFocusedDateTo: false,
         };
     },
     methods: {
         makeDatetimeValue(dt) {
             return dt.toFormat('yyyy-MM-dd');
         },
         initializePeriod() {
             this.setTerm(makeStartOfToday(),makeEndOfToday());
             this.initializePickerRange();
         },
         initializePickerRange() {
             this.minEdgeOfDateString = minEdgeOfDateString();
             this.maxEdgeOfDateString = maxEdgeOfDateString();
         },
         setDateFrom(dt) {
             this.dateFrom = this.lastValidDateFrom = this.makeDatetimeValue(dt);
         },
         setDateTo(dt) {
             this.dateTo = this.lastValidDateTo = this.makeDatetimeValue(dt);
         },
         setDateFromStr(dtStr) {
             this.setDateFrom(DateTime.fromISO(dtStr));
         },
         setDateToStr(dtStr) {
             this.setDateTo(DateTime.fromISO(dtStr));
         },
         setTerm(dtFrom,dtTo) {
             this.setDateFrom(dtFrom);
             this.setDateTo(dtTo);
         },
         updateEdgeOfSelectedDate() {
             this.setMinEdgeOfSelectedDate(DateTime.fromISO(this.dateTo));
             this.setMaxEdgeOfSelectedDate(DateTime.fromISO(this.dateFrom));
         },
         setMinEdgeOfSelectedDate(dt) {
             if (dt.invalid) return;

             const minEdge = minEdgeOfDate();
             const targetDate = DateTime.max(minEdge, makeMinEdgeOfSelectedDate(dt));
             this.minEdgeOfDateString = targetDate.toISODate();
         },
         setMaxEdgeOfSelectedDate(dt) {
             if (dt.invalid) return;

             const maxEdge = maxEdgeOfDate();
             const targetDate = DateTime.min(maxEdge, makeMaxEdgeOfSelectedDate(dt));
             this.maxEdgeOfDateString = targetDate.toISODate();
         },
         setSelectedDateFrom(dFromStr) {
             if (dFromStr == null) dFromStr = this.dateFrom;
             let dFrom = DateTime.fromISO(dFromStr);
             if (dFrom.invalid) return;

             const minDate = minEdgeOfDate();
             dFrom = dFrom < minDate ? minDate : dFrom;
             this.setDateFrom(dFrom);
         },
         setSelectedDateTo(dToStr) {
             if (dToStr == null) dToStr = this.dateTo;
             let dTo = DateTime.fromISO(dToStr);
             if (dTo.invalid) return;

             const minDate = minEdgeOfDate();
             dTo = dTo < minDate ? minDate : dTo;
             this.setDateTo(dTo);
         },
         adjustDateFrom() {
             let dFrom = DateTime.fromISO(this.dateFrom);
             const dTo = DateTime.fromISO(this.dateTo);
             if (dFrom.invalid) return;

             const minDate = minEdgeOfDate();
             dFrom = dFrom < minDate ? minDate : dFrom;

             if (dTo.invalid) return;

             const edgeOfFrom = makeMinEdgeOfSelectedDate(dTo);
             const edgeOfTo = makeMaxEdgeOfSelectedDate(dFrom);
             if (dFrom < edgeOfFrom) {
                 this.setDateFrom(edgeOfFrom);
             } else if (edgeOfTo < dFrom) {
                 this.setDateFrom(edgeOfTo);
             } else if (dTo < dFrom) {
                 this.setDateFrom(dTo);
             }
         },
         adjustDateTo() {
             let dTo = DateTime.fromISO(this.dateTo);
             const dFrom = DateTime.fromISO(this.dateFrom);
             if (dTo.invalid) return;

             const minDate = minEdgeOfDate();
             dTo = dTo < minDate ? minDate : dTo;

             if (dFrom.invalid) return;

             const edgeOfFrom = makeMinEdgeOfSelectedDate(dTo);
             const edgeOfTo = makeMaxEdgeOfSelectedDate(dFrom);
             if (edgeOfTo < dTo) {
                 this.setDateTo(edgeOfTo);
             } else if (dTo < edgeOfFrom) {
                 this.setDateTo(edgeOfFrom);
             } else if (dTo < dFrom) {
                 this.setDateTo(dFrom);
             }
         },
         fixDateFrom() {
             this.handleChangeFrom();
             if (!this.validateDate(this.$refs.dateFrom)) {
                 this.dateFrom = this.lastValidDateFrom;
             } else if (this.isFocusedDateFrom && this.validateDate(this.$refs.dateTo)) {
                 this.adjustDateTo();
             } else {
                 this.setDateFromStr(this.dateFrom);
             }
             this.isFocusedDateFrom = false;
         },
         fixDateTo() {
             this.handleChangeTo();
             if (!this.validateDate(this.$refs.dateTo)) {
                 this.dateTo = this.lastValidDateTo;
             } else if (this.isFocusedDateTo && this.validateDate(this.$refs.dateFrom)) {
                 this.adjustDateFrom();
             } else {
                 this.setDateToStr(this.dateTo);
             }
             this.isFocusedDateTo = false;
         },
         handleChangeFrom() {
             console.debug('BaseDatepickerComponent.handleChangeFrom() called');
         },
         handleChangeTo() {
             console.debug('BaseDatepickerComponent.handleChangeTo() called');
         },
         validateDate(ref) {
             if (ref == null) return false;

             const isValid = ref.validity.valid;
             return isValid;
         },
         onKeyDownDateFrom(e) {
             if (event.key === 'Delete' || event.key === 'Backspace') {
                 this.dateFrom = '';
             }
         },
         onKeyDownDateTo(e) {
             if (event.key === 'Delete' || event.key === 'Backspace') {
                 this.dateTo = '';
             }
         },
         onFocusDateFrom(e) {
             this.isFocusedDateFrom = true;
             this.fixDateTo();
             this.isFocusedDateTo = false;
         },
         onFocusDateTo(e) {
             this.isFocusedDateTo = true;
             this.fixDateFrom();
             this.isFocusedDateFrom = false;
         },
         onFocusOutDateFrom(e) {
             this.fixDateFrom();
             this.isFocusedDateFrom = false;
         },
         onFocusOutDateTo(e) {
             this.fixDateTo();
             this.isFocusedDateTo = false;
         },
         handleWatchDateFrom(newValue,oldValue) {
             if (!this.realtimeValidation) return;
             this.handleChangeFrom();
             if (this.validateDate(this.$refs.dateFrom)) {
                 this.setSelectedDateFrom(newValue);
             } else {
                 this.dateFrom = this.lastValidDateFrom;
             }

         },
         handleWatchDateTo(newValue,oldValue) {
             if (!this.realtimeValidation) return;
             this.handleChangeTo();
             if (this.validateDate(this.$refs.dateTo)) {
                 this.setSelectedDateTo(newValue);
             } else {
                 this.dateTo = this.lastValidDateTo;
             }

         },
     },
     watch: {
         'dateFrom': {
             handler(newValue,oldValue) {
                 this.handleWatchDateFrom(newValue,oldValue);
             },
             immediate: true,
         },
         'dateTo': {
             handler(newValue,oldValue) {
                 this.handleWatchDateTo(newValue,oldValue);
             },
             immediate: true,
         },
     },
 }
</script>
