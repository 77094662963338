<template>
</template>

<script>
 //import { mapGetters } from 'vuex';
 import Swal from 'sweetalert2'
 import { getCookie, setCookie } from '@/composables/common';
 import { getModalContentString } from '@/template/surveymodal';

 export default {
     props: {
     },
     computed: {
     },
     data: function() {
         return {
             doOpenSurvey: false,
             url: 'https://forms.office.com/pages/responsepage.aspx?id=jOQhCWc-fUSYk7BfRSy44t-kYeWm-RZKgBOiJ9h9VEdUMExVTDQ0RklMQk9FQVVYSElMS1ZaSkpUSy4u&embed=true',
             windowName: 'newWindow',
             windowFeatures: 'width=800,height=600,resizable,scrollbars',
         };
     },
     mounted: async function() {
         this.doOpenSurvey = false;

//         if(!getCookie("survey")){
//             // awaitなので先に設定する
//             setCookie('survey', "firstShow", 1);
//             await this.showDialog();
//             const hiddenDia = document.getElementById("disShowDial");
//             if (!this.doOpenSurvey && hiddenDia.checked) {
//                 setCookie('survey', "noShow", 365*50);
//             }
//         }
     },
     methods: {
         openSurvey: function() {
             this.doOpenSurvey = true;
             window.open(this.url, this.windowName, this.windowFeatures);
             // 2024/05/24 : アンケート開くのCookie設定削除(アイリス依頼対応)
             //setCookie('survey', "clicked")
             Swal.close();
         },
         async showDialog() {
             const customDialogTemplate = getModalContentString();
             const { isConfirmed } = await Swal.fire({
                 html: customDialogTemplate,
                 focusConfirm: false,
                 showCloseButton: true,
                 showCancelButton: false,
                 showConfirmButton: false,
                 allowOutsideClick: false,
                 customClass: {
                     popup: 'custom-popup-class',
                     content: 'custom-content-class',
                 },
                 didOpen: () => {
                     const button = document.getElementById('jumpSite');
                     button.addEventListener('click', this.openSurvey);
                 }
             });
             return {
                 closed: !isConfirmed,
             };
         },
     },
 }
</script>
