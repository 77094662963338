<template>
    <div>
        <div class="row label-color mb-3">
            <div v-show="hasCompanySelectAuth(roleId)" class="col-sm-6 col-md-4 col-lg-3">
                <label for="select-company">会社名</label>
                <CompanyList
                    ref="companyList"
                    elm-id="select-company"
                    :store-id="storeId"
                    :include-all="true"
                    :show-with-code="hasCompanySelectAuth(roleId)"
                    @data-loaded="handleDataLoaded"
                    label-all="全て"
                ></CompanyList>
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
                <label for="select-location">拠点名</label>
                <LocationList
                    ref="locationList"
                    elm-id="select-location"
                    :store-id="storeId"
                    :include-all="true"
                    @data-loaded="handleDataLoaded"
                    label-all="全て"
                ></LocationList>
            </div>
            <div class="col-md-2 ms-auto mt-3 mt-md-0">
                <button
                    :disabled="isLoading"
                    id="btn_save"
                    type="button"
                    class="btn btn-add-new icon-button ms-auto"
                    data-bs-toggle="modal"
                    data-bs-target="#vehicleModal"
                    @click="onClickRegister"
                >
                    <img src="/images/add.svg" width="20px" />
                    <span>車両の新規追加</span>
                </button>

            </div>
        </div>

        <div class="row mb-3">
            <div class="col-sm-6 col-md-4 col-lg-3">
                <label for="select-vehicle-no">車両番号</label>
                <input id="select-vehicle-no"
                       v-model="vehicleNo"
                       @keypress.enter.prevent
                       autocomplete="false"
                       type="text"
                       class="form-control"
                       name="select-vehicle-no"
                       placeholder=""
                >
            </div>
        </div>

        <div class="row btn-spacer"></div>

        <div class="row">
            <div class="col"></div>
            <div class="col-12 col-md-6 d-flex justify-content-center">
	        <button
                    class="btn mx-1 btn-reset flex-fill"
                    @click="onClickClearSearchParams"
                >
		    検索条件をリセット
	        </button>
	        <button
                    :disabled="isLoading"
                    class="btn mx-1 btn-search flex-fill"
                    @click="onClickSearch"
                >
                    <img src="/images/search.svg" width="20px" />
                    この条件で検索
	        </button>
            </div>
            <div class="col"></div>
        </div>
        <div class="row">
            <div class="col ms-auto text-end">
                <button
                    type="button"
                    class="btn btn-light link-style-button"
                    id="import_vehicle_csv"
                    style="cursor:pointer;"
                    @click="onClickImportVehicle"
                >
                    <img src="/images/upload.svg"
                         class="calendar_div mx-1"
                         width="20"
                         height="20"
                    />
                    <span class="select_notconfirm">車両の一括登録</span>
                </button>
                <button
                    type="button"
                    class="btn btn-light link-style-button"
                    id="export_vehicle_xlsx"
                    style="cursor:pointer;"
                    @click="onClickExportVehicle"
                >
                    <img src="/images/download.svg"
                         class="calendar_div mx-1"
                         width="20"
                         height="20"
                    />
                    <span class="download_data">ダウンロード</span>
                </button>

            </div>
        </div>
        <div v-if="hasPortableLicense" class="row mb-3 mb-3 text-msg">
            ※車両設定で追加した車両情報はALPiTのアプリで使用いたします。顔認証据置型アルコールチェッカーに車両の共有は行われません。
        </div>
    </div>
</template>

<script>
 import axios from '@/composables/axios.js';
 import { hasCompanySelectAuth } from '@/composables/common.js';
 import { createCompanyLocationParamState } from '@/stores/companylocation';
 import { useCommonSearchParamState } from '@/stores/commonSearchParam';
 import { useCommonStore } from '@/stores/common';
 import CompanyList from '@/components/CompanyList.vue';
 import LocationList from '@/components/LocationList.vue';

 export default {
     setup() {
         return {
             hasCompanySelectAuth,
         };
     },
     components: {
         CompanyList,
         LocationList,
     },
     props: {
         roleId: {type: Number, required: true},
     },
     computed: {
         hasPortableLicense() {
             // XXX 既存のコードでの判定方法。
             if (!this.roleIdsToNotice.includes(this.roleId)) return false;
             return this.licenseTypeCount >= 2;
         },
         isLoading() {
             if (this.params.isLoading) return true;
             return false;
         },
     },
     data: function() {
         return {
             storeId: 'search-list',
             params: createCompanyLocationParamState('search-list'),
             licenseTypeCount: 0,
             roleIdsToNotice: [3,4],
             vehicleNo: '',
             initialized: false,
             listComponentStates: {company: true, location: true},
         };
     },
     mounted: async function() {
         await this.updateLicenseTypeCount();
     },
     methods: {
         async updateLicenseTypeCount() {
             const response = await axios.get('/license/typecount');
             this.licenseTypeCount = response.data.count*1;
         },
         onClickSearch(event) {
             event.preventDefault();
             reloadTable();
         },
         onClickImportVehicle(event) {
             VehicleImportFormShow();
         },
         onClickExportVehicle(event) {
             handleClickExportVehicleXlsx();
         },
         async onClickRegister() {
             vehicleRegistration();
         },
         onClickClearSearchParams(event) {
             event.preventDefault();
             this.$refs.companyList.clearSelection();
             this.$refs.locationList.clearSelection();
             this.vehicleNo = '';
         },
         handleDataLoaded(data) {
             for (const [key, value] of Object.entries(data)) {
                 this.listComponentStates[key] = value;
             }
             if (this.initialized) return;
             const allCompleted = Object.values(this.listComponentStates)
                                        .every(value => value === true);
             if (allCompleted) {
                 reloadTable();
                 this.initialized = true;
             }
         },
     },
     watch: {
     },
 }
</script>
