<template>
    <div v-if="!isMaintenanceMode" class="row mb-3">
	<div class="gap-1 d-flex justify-content-end">
	    <div class="col-4 btn-group" role="group" aria-label="Basic example">
		<button
                    :disabled="isLoading"
                    @click="onClickUpload"
                    type="button" class=" btn btn-light mx-1" id="import_license_csv" style="cursor:pointer;" >
		    <img :src="uploadImagePath" class="calendar_div mx-1" width="20" height="20" />
		    <span class="select_notconfirm">アップロード</span>
		</button>
	    </div>
	    <div class="col-4 btn-group" role="group" aria-label="Basic example">
		<button
                    :disabled="isLoading"
                    @click="onClickDownload"
                    type="button" class="btn btn-light" id="export_license_csv" style="cursor:pointer;">
		    <img :src="downloadImagePath" class="calendar_div mx-1" width="20" height="20" />
		    <span class="download_data">ダウンロード</span>
		</button>
	    </div>
	</div>
    </div>
</template>

<script>
 import axios from '@/composables/axios.js';
 import { createCompanyLocationParamState } from '@/stores/companylocation';
 import { useCommonStore } from '@/stores/common';
 export default {
     components: {
     },
     props: {
         storeId:{type: String, require: true},
         uploadImagePath: {type: String, requrired: true},
         downloadImagePath: {type: String, requrired: true},
     },
     computed: {
         // XXX mapper
         isMaintenanceMode() {
             return this.st.driver.isMaintenanceMode;
         },
         isLoading() {
             if (this.params.isLoading) return true;
             return false;
         },
     },
     data: function() {
         return {
             params: createCompanyLocationParamState(this.storeId),
             st: useCommonStore(),
         };
     },
     mounted: async function() {
     },
     methods: {
         onClickUpload() {
             const div = document.getElementById('error_message');
             div.className = '';
             document.getElementById('error_message').innerHTML = "";

             document.getElementById("importerror").innerHTML = "";
             document.getElementById("importfile").value = "";
             $('#importerror').hide();
             //$('#importerror').hide();
             $('#license_importForm').modal('show');
             document.body.style = "overflow:hidden !important";
         },
         onClickDownload() {
             export_license_csv();
         },
     },
     watch: {
     },
 }
</script>
<style scoped>
 #btn_showdlist,#btn_back,#import_license_csv,#export_license_csv {
     background-color: #e6f5ff;
     border-top-color: #e6f5ff;
     border-left-color: #e6f5ff;
     border-right-color: #e6f5ff;
     border-bottom-color: #e6f5ff;
 }
</style>
