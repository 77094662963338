export function getModalContentString() {
    return `
<div id="survey-modal">
  <div class="content">
    <img class="logo" src="/images/login_new_logo.png">
    <h2>アンケートご協力をお願いします</h2>
    <p class="jump">
      <button id="jumpSite" class="linkButton">
        アンケート回答へ移動する
      </button>
    </p>
    <div>
      <div class="check">
        <input type="checkbox" id="disShowDial" name="subscribe" value="newsletter">
        <label for="disShowDial">次回からこのダイアログを表示しない</label>
      </div>
    </div>
  </div>
</div>
    `;
}
